import {Article} from "./schema/article.class";

export const articles = {
    section0: {
        title: "Selected articles",
        id: "section-0",
        articles: [
            new Article("An Analysis of Frameworks for Microservices", "Our results show that although the KumuluzEE supports few characteristics of the microservices architecture, it is easier to use, especially, for newcomers.", "https://ieeexplore.ieee.org/document/8786337"),
            new Article("Microservices in the Cloud, Part One", "Everything you need to know!", "https://dzone.com/articles/microservices-in-the-cloud-part-one"),
            new Article("Java EE Microservices on Kubernetes with KumuluzEE - DZone Microservices", "Deploy and autoscale KumuluzEE microservices on Kubernetes with service discovery, configuration and health checks.", "https://dzone.com/articles/java-ee-microservices-on-kubernetes-with-kumuluzee"),
            new Article("Java EE 8 Microservices", "Build the foundation of Cloud Native applications with microservices using Java EE 8.", "https://www.packtpub.com/application-development/java-ee-8-microservices-video"),
            new Article("Eclipse Microservices and Service Discovery", "Discover service discovery, an essential part of microservice architecture becomes necessary when we start using containers and container orchestration mechanisms.", "https://jaxenter.com/eclipse-microservices-service-discovery-138577.html"),
            new Article("Eclipse MicroProfile", "Eclipse MicroProfile in spotlight, september 2017", "https://www.eclipse.org/community/eclipse_newsletter/2017/september/"),
            new Article("Eclipse Microservices and Service Discovery", "Microservices are becoming increasingly important for enterprise applications in general and for the Java EE platform, which is a widely used...", "http://www.eclipse.org/community/eclipse_newsletter/2017/september/article5.php"),
            new Article("Building a resilient Microservice? All you need is Eclipse MicroProfile Fault Tolerance!", "Building a resilient microservice? No need to ask why...", "http://www.eclipse.org/community/eclipse_newsletter/2017/september/article4.php"),
            new Article("Eclipse MicroProfile: 5 Things You Need to Know", "KumuluzEE has the fastest startup and smallest footprint microservices.", "https://readlearncode.com/java-ee/eclipse-microprofile-5-things-you-need-to-know/"),
            new Article("Java EE MicroProfile With KumuluzEE - DZone Java", "Despite MicroProfile's hype, KumuluzEE already offers service discovery, registration, and configuration support for microservices in a Java EE environment.", "https://dzone.com/articles/java-ee-microprofile-with-kumuluzee"),
            new Article("Scalable Microservices Using Modern Java-Based Frameworks - DZone Integration", "Take a look at KumuluzEE, a Java-based framework specifically designed for developers tooling around with microservices.", "https://dzone.com/articles/scalable-microservices-using-kumuluz-ee-framework"),
            new Article("Implementing Microservices Using KumuluzEE - DZone Integration", "Here's a great guide to getting started with microservices on KumuluzEE.", "https://dzone.com/articles/implementing-microservices-using-kumuluzee"),
            new Article("Docker, Kubernetes, and Microservices Replay from Devoxx 2015", "Java gives us Write Once Run Anywhere (WORA) because of the common abstraction provided by Java Virtual Machine. The binary byte code produced by Java is understood by the JVM running on multiple operating systems...", "http://blog.arungupta.me/docker-kubernetes-microservices-devoxx-2015/"),
            new Article("Conheça o KumuluzEE – seu novo framework para Microservices", "Parece que a nova buzzword é realmente Microservices. A definição do Adam Bien parece perfeita: é um serviço que tem máxima coesão e mínimo acoplamento.", "http://blog.caelum.com.br/conheca-o-kumuluzee-seu-novo-framework-para-microservices/"),
            new Article("Java EE and Microservices in 2016?", "At the end of 2015 Steve Millidge from C2B2 and a co-founder of Payara predicted that 2016 would be the year of Java EE microservices. Many efforts would tend to agree, including WildFly, TomEE and the KumuluzEE framework...", "https://www.infoq.com/news/2016/02/javaee-microservices"),
            new Article("Implementasi microservice dengan Java EE pada KumuluzEE", "Melanjutkan blog sebelumnya, kali ini kita akan membuat REST API menggunakan standar Java EE pada framework KumuluzEE. Tambahkan modul...", "http://blog.nostratech.com/2016/03/implementasi-microservice-dengan-java.html"),
            new Article("Dolphin Platform & KumuluzEE: JavaEE Microservices with dynamic and rich frontends", "Have you heard of KumuluzEE that is one of the 2015 Java Duke’s Choice Award winners? Well, if you haven’t heard about this cool project you should have a look. KumuluzEE is a JavaEE ba…", "http://www.guigarage.com/2015/11/dolphin-platform-kumuluzee-javaee-microservices-with-dynamic-and-rich-frontends/"),
            new Article("Video: Philipe Tjon A Hen - KumuluzEE - developing microservices using Java EE technologies", "KumuluzEE is een lichtgewicht framework om microservices mee te bouwen die gebruik maken van standaard JavaEE technologie. Kunnen we gebruikmakend van standa...", "https://www.youtube.com/watch?v=oLHy2VI00G0"),
            new Article("KumuluzEE on Oracle Application Container Cloud", "In this blog post, I will describe how to deploy the CloudEE Duke application packaged in a Kumuluz EE über-jar to Oracle Application Container Cloud...", "http://www.agilejava.eu/2018/03/22/kumuluzee-on-oracle-application-container-cloud/"),
            new Article("GraphQL with KumuluzEE", "KumuluzEE GraphQL has been easy-to-use and is a good source of inspiration for the MicroProfile GraphQL specification and its future implementations.", "https://jefrajames.wordpress.com/2019/03/08/graphql-with-kumuluzee/"),
            new Article("Tracing KumuluzEE Microservices With Jaeger and Zipkin", "In this post, we run through how to create basic microservices and then perform tracing on them with these helpful platforms.", "https://dzone.com/articles/tracing-kumuluzee-microservices-with-jaeger-and-zi"),
            new Article("MicroProfile Runtimes Overview", "", "http://adambien.blog/roller/abien/entry/microprofile_runtimes_overview"),
            new Article("Gradual Migration from Java EE to MicroProfile", "The goal of MicroProfile.IO is to optimise Java EE for a micro-service architecture. It is based on some of the Java EE specifications and standardise a few technologies from the micro-services space.", "https://blog.payara.fish/gradual-migration-from-java-ee-to-microprofile"),
            new Article("KumuluzEE for Standalone Java EE Microservices", "In this post, I would like to start with KumuluzEE which advertises the easy migration of Java EE applications to cloud-native microservices on it’s website. The advantage, like with Thorntail, to me is that I can code against the regular Java EE APIs and thus do not have to learn a new framework.", "http://dplatz.de/blog/2018/kumuluzee.html"),
            new Article("Eclipse MicroProfile Starter", "The new Eclipse MicroProfile Starter is live and it's really easy to generate a new project.", "https://kodnito.com/posts/eclipse-microprofile-starter/"),
        ]
    }
};
